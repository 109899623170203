<template>
  <div>
    <t-card v-if="headers && headers.length > 0" :title="noTitle ? undefined : title" class="my-3">
      <template #default>
        <template v-if="filters || textFilter">
          <div class="d-flex mb-3 align-items-center">
            <div v-if="textFilter" class="text-filter-container">
              <content-filter v-model="textFilterStr"
                              prepend-icon="filter"
                              placeholder="Filter by Customer Name"
                              class="mb-0 mr-5" />
            </div>
            <template v-if="filters">
              <b-form-group v-for="(optSet, optSetIndex) in options"
                            :key="optSetIndex"
                            class="mb-0 mr-5">
                <label class="d-inline"><b>{{optSetIndex}}:</b></label>
                  <b-form-checkbox-group :checked="selected[optSetIndex]"
                                         @input="selected[optSetIndex] = [...$event]"
                                         :options="optSet"
                                         button-variant="secondary"
                                         class="d-inline"
                                         switches />
              </b-form-group>
            </template>
          </div>
        </template>
        <table class="table table-hover">
          <thead>
            <th v-for="header in headers"
                :key="header+'1'"
                :class="{ 'text-right': isNumericField(header) }">
              {{$filters.convertFromCamelCase(header)}}
            </th>
            <th v-if="actions.row && actions.row.length > 0" class="actionsCell">Actions</th>
          </thead>
          <tbody>
            <tr v-for="(listItems, listItemIndex) in computedListObject" :key="'listItem'+listItemIndex">
              <td v-for="header in headers"
                  :key="'headerRowClick'+header"
                  :class="{ 'bg-disabled': listItems.hasOwnProperty('status') && listItems.status !== 'ENABLED', 'text-right': isNumericField(header) }"
                  @click="rowClick(listItems, listItemIndex)">
                <span v-if="keySettings[header] && keySettings[header].type === 'checkbox'">
                  <b-form-checkbox :checked="listItems[header]" @input="listItems[header] = $event" value="true" unchecked-value="false"></b-form-checkbox>
                </span>
                <span v-else-if="listItems[header]">
                  <h1 class="d-inline">
                    <p-tag v-if="header === 'name' && listItems.hasOwnProperty('type')"
                           :severity="convertButtonVariant(customerTypeBadge(listItems).variant)"
                           v-p-tooltip.top="$filters.capitalize(listItems.type)"
                           class="settings-badge mr-2">
                      {{customerTypeBadge(listItems).letter}}
                    </p-tag>
                  </h1>
                  <span :class="{ 'text-muted font-italic': listItems.hasOwnProperty('status') && listItems.status !== 'ENABLED' }">{{isMoney(header, listItems[header])}}</span>
                </span>
              </td>
              <td v-if="actions.row && actions.row.length > 0"
                  class="actionsCell"
                  :class="{ 'bg-disabled': listItems.status !== 'ENABLED' }">
                <div v-for="(action, actionIndex) in actions.row" :key="`action-${listItemIndex}-${actionIndex}`">
                  <t-button variant="link"
                         class="px-0"
                         @click="emitEvent(action.event, listItems, listItemIndex)">
                    <fluency-icon v-if="action.icon" :type="action.icon" /> {{action.label}}
                  </t-button>
                </div>
              </td>
            </tr>
            <tr>
              <template v-if="showNewRow">
                <td v-for="(header, headerKey) in headers"
                    :key="headerKey"
                    :class="{ 'actionsCell': header === 'package' }">
                  <div class="form-group">
                    <div v-if="header === 'package'" class="text-muted">
                      Set Package after saving
                    </div>
                    <div v-else-if="['accounts', 'lastMonthSpend', 'customerLaunchDate'].includes(header)" class="text-center">&mdash;</div>
                    <input v-else
                           v-model="newRow[header]"
                           :id="'formGroupInput'+header"
                           type="text"
                           :placeholder="header"
                           class="form-control" />
                  </div>
                </td>
              </template>
              <td :colspan="colspan" v-if="!showNewRow">
                <a href="#" @click.prevent="add">Add Row</a>
              </td>
              <td>
                <t-button variant="primary" class="py-1 px-3" @click.prevent="save(newRow)">Save</t-button>
                <t-button variant="link" class="py-1 px-3" @click.prevent="cancel()">Cancel</t-button>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </t-card>
    <div class="m-5 p-5"></div>
  </div>
</template>

<script>
import ContentFilter from './content-filter'
import _ from 'lodash'
import { convertButtonVariant } from 'core-ui/primeVueBridge/convertBvToPrime'

export default {
  name: 'DataListView',
  components: { ContentFilter },
  // Some notes for props:
  // getQuery is the inital get query for the object
  // new tells us you want to do a create vs a view / edit
  // getter is the query string / path to get the data model
  // setter is the query string to set a data model
  props: ['getter', 'setter', 'getQuery', 'primaryNode', 'actionItems', 'title', 'renderObject', 'keySettings', 'noTitle', 'sortable', 'textFilter', 'filters', 'defaultFilters', 'lastUpdate'],
  data () {
    return {
      query: '',
      responseObj: {},
      fullObj: {},
      setQuery: '',
      headers: [],
      actions: {
        // headers: [],
        rows: []
      },
      newRow: {},
      showNewRow: false,
      selected: {},
      options: {},
      textFilterStr: ''
    }
  },
  setup () {
    return { convertButtonVariant }
  },
  mounted () {
    if (this.getter) {
      this.query = this.getter
      this.loadQuery()
    } else if (this.renderObject && this.renderObject !== '') {
      this.handleResponse(this.renderObject)
    }

    if (this.actionItems) {
      this.$setCompat(this, 'actions', this.actionItems)
    }

    let selectedPredefined = false

    if (this.defaultFilters) {
      this.$setCompat(this, 'selected', this.defaultFilters)
      selectedPredefined = true
    }

    this?.filters?.forEach((filter) => {
      const key = filter.name
      const opts = []
      const sels = []

      if (filter.data) {
        filter.data.forEach(v => {
          opts.push({ text: _.capitalize(v), value: v })
          sels.push(v)
        })

        this.$setCompat(this.options, key, opts)

        if (!selectedPredefined) {
          this.$setCompat(this.selected, key, sels)
        }
      }
    })
  },
  watch: {
    lastUpdate: {
      handler (newVal, oldVal) {
        this.$setCompat(this, 'computedListObject', newVal)
      },
      deep: true
    },
    renderObject: function () {
      if (this.renderObject && this.renderObject !== '') {
        this.handleResponse(this.renderObject)
      }
    }
  },
  computed: {
    colspan: function () {
      if (this.actions.row && this.actions.row.length > 0) {
        return this.headers.length
      } else {
        return this.headers.length - 1
      }
    },
    computedListObject: {
      set: function (newVal) {
        if (newVal) {
          const updatedId = newVal.customerId

          Object.entries(this.responseObj).forEach(o => {
            const key = o[0]
            const val = o[1]

            if (updatedId === val.customerId) {
              this.$setCompat(this.responseObj, key, newVal)
            }
          })
        }
      },
      get: function () {
        let copy = _.cloneDeep(this.responseObj)

        if (this.sortable) {
          // just sort alphabetically by name
          copy.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)
        }

        if (this.filters) {
          // show/hide from table based on filters prop
          this.filters.forEach(filter => {
            const field = filter.field
            const name = filter.name

            if (filter.data) {
              filter.data.forEach(d => {
                copy = copy.filter(c => {
                  switch (field) {
                    case 'status':
                      if (c[field] === 'ENABLED' && this.selected[name].includes('ENABLED')) {
                        return true
                      }

                      if (c[field] !== 'ENABLED' && this.selected[name].includes('DISABLED')) {
                        return true
                      }
                      break
                    case 'other':
                      break
                    default:
                      return this.selected[name].includes(c[field])
                  }

                  return false
                })
              })
            }

            if (this.textFilterStr) {
              copy = copy.filter(c => {
                return c.name.toLowerCase().indexOf(this.textFilterStr.toLowerCase()) > -1
              })
            }
          })
        }

        return copy
      }
    }
  },
  methods: {
    isNumericField (header) {
      switch (header) {
        case 'accounts':
        case 'postalCode':
        case 'phoneNumber':
        case 'lastMonthSpend':
          return true
      }

      return false
    },
    isMoney (header, value) {
      switch (header) {
        case 'lastMonthSpend':
          return this.$filters.currency(value)
      }

      return value
    },
    customerTypeBadge (item) {
      const disabled = item.status !== 'ENABLED'
      switch (item.type) {
        case 'CLIENT':
          let color = 'info'
          const health = item?.health?.toLowerCase()

          switch (health) {
            case 'red':
              color = 'danger'
              break
            case 'green':
              color = 'success'
              break
            case 'yellow':
              color = 'warning'
              break
            default:
              color = 'info'
              break
          }
          return { letter: 'C', variant: (disabled) ? 'disabled' : color }
        case 'OTHER':
          return { letter: 'O', variant: (disabled) ? 'disabled' : 'muted' }
        case 'DEMO':
          return { letter: 'D', variant: (disabled) ? 'disabled' : 'muted' }
        case 'PROSPECT':
          return { letter: 'P', variant: (disabled) ? 'disabled' : 'muted' }
        case 'TEST':
          return { letter: 'T', variant: (disabled) ? 'disabled' : 'muted' }
        default:
          return { letter: 'U', variant: (disabled) ? 'disabled' : 'muted' }
      }
    },
    loadQuery: async function () {
      let getQuery = {}
      if (this.getQuery && this.getQuery !== '') {
        getQuery = this.getQuery
      }
      let response = false
      if (typeof this.getter === 'function') {
        response = await this.getter(getQuery)
      } else {
        response = await this.$res.fetch[this.getter](getQuery)
      }
      // const response = await this.$res.fetch[this.getter](getQuery)
      this.handleResponse(response)
    },
    handleResponse: function (response) {
      if (response) {
        let headers = []
        if (this.primaryNode) {
          console.log('%c TRYING TO LOAD A PRIMARY NODE - THIS IS NOT SUPPORTED!!!', 'color: red;')
          return

          // headers = Object.keys(response[0][this.primaryNode])
          // const listData = []
          // for (let i = 0; i < response.length; i++) {
          //   listData.push(response[i][this.primaryNode])
          // }
          // this.$setCompat(this, 'responseObj', listData)
          // this.$setCompat(this, 'fullObj', response)
        } else {
          headers = Object.keys(response[0])
          this.$setCompat(this, 'responseObj', response)
          this.$setCompat(this, 'fullObj', response)
        }
        if (this.keySettings.customHeader) {
          headers = this.keySettings.customHeader
        }
        for (const key in this.keySettings) {
          if (headers.indexOf(key) > -1 && this.keySettings[key].excluded) {
            headers.splice(headers.indexOf(key), 1)
          }
        }
        this.$setCompat(this, 'headers', headers)
      }
    },
    emitEvent: function (event, item, actionIndex) {
      this.$eventBus.$emit(event, item)
    },
    rowClick: function (item, listItemIndex) {
      if (this.actions && this.actions.row && this.actions.row[0] && this.actions.row[0].event) {
        this.$eventBus.$emit(this.actions.row[0].event, item)
      }
    },
    save: async function (row) {
      let query = {}
      if (this.primaryNode) {
        query[this.primaryNode] = row
      } else {
        query = row
      }

      // copy any fields that are important
      for (const field in this.keySettings) {
        if (this.primaryNode) {
          console.log('%c TRYING TO SAVE A PRIMARY NODE - THIS IS NOT SUPPORTED!!!', 'color: red;')
          // if (this.keySettings[field].copyOnAdd && this.fullObj[0][this.primaryNode][field]) {
          //   query[this.primaryNode][field] = this.fullObj[0][this.primaryNode][field]
          // }
        } else {
          if (this.keySettings[field].copyOnAdd && this.responseObj[0][field]) {
            query[field] = this.responseObj[0][field]
          }
        }
      }

      let response = false
      // debugger
      if (typeof this.setter === 'function') {
        response = await this.setter(query)
      } else {
        response = await this.$res.set[this.setter](query)
      }
      if (response) {
        this.$toast('Item Saved', 'success')
        this.updateViewAfterSave(query)
      }
    },
    add: function () {
      this.$setCompat(this, 'showNewRow', true)
    },
    cancel: function () {
      this.$setCompat(this, 'showNewRow', false)
    },
    updateViewAfterSave: function (query) {
      if (this.primaryNode) {
        this.responseObj.push(query[this.primaryNode])
      } else {
        this.responseObj.push(query)
      }
      this.$setCompat(this, 'newRow', {})
      this.$setCompat(this, 'showNewRow', false)
    },
    removeWhitespace: function (val) {
      return this.$filters.removeWhitespace(val)
    }
  }
}
</script>
<style lang="scss" scoped>
  .settings-badge {
    width: 25px;
    max-width: 25px;
    text-align: center;
  }
  .badge-disabled {
    background-color: var(--p-surface-300);
  }
  .bg-disabled {
    background-color: $fluency-lightergray;
  }
  .text-filter-container {
    width: 300px;
  }
  .actionsCell {
    width: 140px !important;
  }
</style>
